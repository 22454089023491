import React, { useContext } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { Container, Section, SectionPageTitle } from "../components/Section";
import { Context } from "../components/context";
import { GatsbyImage } from "gatsby-plugin-image";
import { CloseIcon} from "../components/Icons";
import { getCommonItems } from "../utils";
import { graphql, Link } from "gatsby";
import ProductCarousel from "../components/ProductCarousel";
import DefaultButton from "../components/Button/DefaultButton";
import PrimaryButton from "../components/Button/PrimaryButton";

const TableCompare = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  padding: 0;
  position: relative;

  table {
    margin: 0;
  }
`

const Table = styled.table`
  table-layout: fixed;
  tr {
    border: 0;
    border-bottom: 1px solid #f7f7f7;
  }
  tr:first-child {
    border-top: 1px solid #f7f7f7;
  }

  th:first-child,
  td:first-child {
    padding-left: 10px;
  }

  td.pro-heading {
    position: sticky;
    left: 0;
    z-index: 3;
    text-align: right;
    font-weight: 500;
    background: #e31d1d;
    color: #fff;
    border-right: 2px solid #f7f7f7;
    width: 100px;
    min-width: 100px;
    font-size: 12px;
    line-height:18px;
    @media (min-width: 768px) {
      width: 150px;
      min-width: 150px;
      font-size: 14px;      
      line-height:20px;
    }
  }
  td.product-origin {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
  }

  td.pro-title {
    padding: 8px 30px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    background-color: #ebf6ff;
    text-align: left;
    color: #062c44;
  }
  td {
    padding: 8px 10px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-align: left;
    background: #deedfd;
    font-size: 14px;
    line-height: 22px;
    color: #062c44;
    min-width: 230px;
    @media (min-width: 768px) {
      min-width: 270px;
    }
  }
  thead th {
    background: #deedfd;
  }
  td.pro-desc {
    vertical-align: top;
    p {
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p, ul, ol{
      font-size: 14px;
      line-height: 22px;
    }
    ul{
      margin: 0 0 0 20px;
      li{
        margin:0;
        padding:0;
      }
    }
  }
  td.product-thumb {
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 2;
    width: 250px;

    @media (min-width: 1200px) {
      width: unset;
    }
    .gatsby-image-wrapper, img {
      padding: 4px;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  }
  td:nth-child(2n + 3) {
    background: #cae2fc;
  }
  thead th:nth-child(2n + 3) {
    background: #cae2fc;
  }
  td.action {
    text-align: center;
  }
`

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  min-width: 24px;
  svg {
    fill: #062c44;
  }
  &:hover {
    border-color: #ff3e00;
    svg {
      fill: #ff3e00;
    }
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button, a {
    margin: 0 5px;
  }
  .btn .text{
    font-size: 14px;
    line-height: 20px;
    padding: 4px 8px;
    font-weight: 400;
  }
  .btn{
    border-radius:4px;
  }
`

const ProductColHeader = styled.td`
  position: sticky;
  top: 0;
  z-index: 2;
  width: 250px;

  @media (min-width: 1200px) {
    width: unset;
  }
`

const ProductHeaderTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: #000;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
  }
`

const ImageThumb = styled.div`
  width: 100%;
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const AddProduct = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    font-size: 24px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    color: #e31d1d;
    border: 2px solid #e31d1d;
    margin-bottom: 10px;
  }
  .text {
    color: #062c44;
    font-size: 16px;
    font-weight: 500;
  }
`


const displayFields = [
  "Product",
  "Price",
  "SKU",
  "Building Specifications",
  "Description",
  "Location",
  "Gauge",
  "Manufacturer",
  "Roof Style",
  "Condition",
  "Building Tags",
  "Actions",
];

const ProductCompare = () => {
  const {
    compareProducts,
    addProductToComparison,
    selectProduct,
    removeProduct,
  } = useContext(Context);

  const renderCell = (field, product, no) => {
    if (!product) {
      if (field !== "Product") {
        if (!compareProducts || compareProducts.length === 0) return null;

        return <td key={no}></td>;
      }
    }

    switch (field) {
      case "Product":
        return (
          <ProductColHeader key={no}>
            {product ? (
              <>
                <ProductHeaderTitle>
                  <span>{product.title}</span>
                  <CloseButton onClick={() => removeProduct(product.id)}>
                    <CloseIcon />
                  </CloseButton>
                </ProductHeaderTitle>
                <ImageThumb>
                  <GatsbyImage
                    image={product.image.gatsbyImageData}
                    alt={product.title}
                  />
                </ImageThumb>
              </>
            ) : (
              <AddProduct
                onClick={async () => {
                  const p = await selectProduct();
                  addProductToComparison("select", p);
                }}
              >
                <span className="icon">+</span>
                <span className="text">Add a Product</span>
              </AddProduct>
            )}
          </ProductColHeader>
        );
      case "Price":
        return (
          <td key={no}>
            <strong>${product.price.toLocaleString("en-US")}</strong>
          </td>
        );
      case "SKU":
        return <td key={no}>{product.skuNumber}</td>;
      case "Building Specifications":
        return (
          <td key={no} className="pro-desc">
            <div
              dangerouslySetInnerHTML={{
                __html: product.specifications.childMarkdownRemark.html,
              }}
            />
          </td>
        );
      case "Description":
        return (
          <td key={no} className="pro-desc">
            {!!product.description && (
              <p>
                {product.description.childMarkdownRemark.html.replace(
                  /<[^>]+>/g,
                  ""
                )}
              </p>
            )}
          </td>
        );
      case "Location":
        return (
          <td key={no}>
            {product.city}, {product.state} {product.zipcode}
          </td>
        );
      case "Gauge":
        return (
          <td key={no}>
            {product["gaugeTubing"] || 0}-Gauge Tubing,{" "}
            {product["gaugePanels"] || 0}-Gauge Panels (Upgrade Available)
          </td>
        );
      case "Manufacturer":
        return <td key={no}>{product.manufacturer}</td>;
      case "Roof Style":
        return <td key={no}>{product.roofStyleType}</td>;
      case "Condition":
        return <td key={no}>{product.buildingCondition}</td>;
      case "Building Tags":
        return <td key={no}>{(product["buildingTags"] || []).join(", ")}</td>;
      case "Actions":
        return (
          <td key={no}>
            <Actions className="action">
              <Link to="tel:4792235995" aria-label="phone number">
                <DefaultButton text="Call Us Now"/>
              </Link>
              <Link to={product.url}>
                <PrimaryButton text="View Details"/>
              </Link>
            </Actions>
          </td>
        );
      default:
        return null;
    }
  };

  const renderHeadLabel = (field) => {
    if (field === "Product") {
      return <td className="pro-heading">Product</td>;
    }

    if (!compareProducts || compareProducts.length === 0) return null;
    return <td className="pro-heading">{field}</td>;
  };

  const products = [...compareProducts];
  while (products.length < 4) {
    products.push(null);
  }

  return (
    <Container>
      <SectionPageTitle textAlign="left" className="h4">
        Product Comparison
      </SectionPageTitle>
      <TableCompare>
        <Table className="table">
          <tbody>
            {displayFields.map((field, j) => (
              <tr key={j}>
                {renderHeadLabel(field)}
                {products.map((p, i) => renderCell(field, p, i))}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableCompare>
    </Container>
  );
};

const SimilarProducts = ({ allProducts }) => {
  const { compareProducts } = useContext(Context);

  if (!compareProducts || compareProducts.length === 0) return null;
  const categories = compareProducts.reduce((currentResult, product) => {
    return [...currentResult, ...product.productCategories.map((c) => c.title)];
  }, []);
  const compareProductIds = compareProducts.map((p) => p.id);
  const products = allProducts.filter((edge) => {
    if (compareProductIds.indexOf(edge.node.id) < 0) {
      if (
        getCommonItems(
          categories,
          edge.node.productCategories.map((pc) => pc.title)
        ).length > 0
      )
        return true;
    }
    return false;
  });

  if (products.length === 0) return null;

  return <ProductCarousel data={products} title="Similar Buildings" />;
};

const Page = ({ data, location }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Compare",
        item: `https://www.engineeredmetalbuildings.com/compare/`,
      },
    ],
  };
  const schema = [breadcrumbSchema];
  return (
    <Layout location={location}>
      <Seo
        title="Compare Metal Buildings - Prices, Specs & Features - EMB Metal Buildings"
        description="Compare metal buildings, prices, features, and specifications to find the perfect structure. Make an informed decision with our comprehensive comparison tool."
        schemaMarkup={schema}
      />
      <Section
        ept="130px"
        epb="0"
        xpt="130px"
        xpb="0"
        pt="100px"
        pb="0"
        bgColor="#fff"
        className="section-compare"
      >
        <ProductCompare />
      </Section>

      <SimilarProducts allProducts={data.allContentfulProduct.edges} />
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ComparePageQuery {
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          specifications {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
